<template>
  <div class="markup-tables flex">
    <va-card class="flex xs12 mb-4" v-if="image">
      <va-card-title style="font-size: 1.5rem;">
        {{ image.name }}
        <va-icon :name="'fa4-download'" class="download-icon" style="padding-left: 1rem; cursor: pointer;" @click="downloadFiles" />
      </va-card-title>
    </va-card>

    <div class="row" style="margin-bottom: 1.5rem;">
      <va-card class="flex xs3 md4 offset--md1" v-if="image">
        <va-card-title style="font-size: 1.5rem;">With Flash</va-card-title>
        <va-card-content v-if="imageLink">
          <img data-bs-toggle="modal" data-bs-target="#flashModal" style="width: 100%; cursor: pointer;" :src="imageLink.flash.jpg" />
        </va-card-content>
      </va-card>
      <va-card class="flex xs3 md4 offset--md2" v-if="image">
        <va-card-title style="font-size: 1.5rem;">Without Flash</va-card-title>
        <va-card-content v-if="imageLink">
          <img data-bs-toggle="modal" data-bs-target="#noFlashModal" style="width: 100%; cursor: pointer;" :src="imageLink.no_flash.jpg" />
        </va-card-content>
      </va-card>
    </div>

    <div class="modal fade" id="flashModal" tabindex="-1" aria-labelledby="flashModal" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content" style="background-color: #000000a6;">
          <div class="modal-header" style="border-bottom: 0;">
            <va-button class="mr-2 mb-2 btn-close" icon="ion_md_close" data-bs-dismiss="modal" />
          </div>
          <div class="modal-body flex-center">
            <img style="height: 100%;" :src="imageLink.flash.jpg" v-if="imageLink" />
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="noFlashModal" tabindex="-1" aria-labelledby="noFlashModal" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content" style="background-color: #000000a6;">
          <div class="modal-header" style="border-bottom: 0;">
            <va-button class="mr-2 mb-2 btn-close" icon="ion_md_close" data-bs-dismiss="modal" />
          </div>
          <div class="modal-body flex-center">
            <img style="height: 100%;" :src="imageLink.no_flash.jpg" v-if="imageLink" />
          </div>
        </div>
      </div>
    </div>

    <va-card class="flex xs12 mb-4" v-if="image">
      <va-card-title style="font-size: 1.5rem;">Type</va-card-title>
      <va-card-content>
        <div class="flex md12">
          {{ image.type }}
        </div>
      </va-card-content>
    </va-card>

    <va-card class="flex xs12 mb-4" v-if="image">
      <va-card-title style="font-size: 1.5rem;">Note</va-card-title>
      <va-card-content>
        <div class="flex md12">
          {{ image.note }}
        </div>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  mounted: async function () {
    this.loader = this.$loading.show()
    try {
      const headers = {
        Authorization: `Bearer ${this.token}`
      }
      // Load image
      const image = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/images/${this.fileId}`, { headers })
      this.image = image.data.image

      // Get image links
      const imageLink = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/images/${this.fileId}/download`, { headers })
      this.imageLink = imageLink.data.image
    } catch (error) {
      this.$router.push({ name: 'login', query: { redirect: `/admin/files/${this.id}` } })
    }
    this.loader.hide()
  },
  data () {
    return {
      loader: null,
      token: this.$store.state.token,
      session: {},
      imageLink: null,
      image: null,
      id: this.$route.params.id,
      fileId: this.$route.params.fileId
    }
  },
  methods: {
    downloadFiles: async function () {
      this.$vaToast.init({
        message: 'Downloading Files. Please wait.',
        iconClass: 'fa-star-o',
        position: 'bottom-right',
        duration: 6000,
        fullWidth: false,
        color: 'primary',
      })
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        // Get image links
        const imageLink = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/images/${this.fileId}/download`, { headers })

        axios({
            url: imageLink.data.image.flash.txt,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'text/plain'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}.txt`
            link.click()
          })

        axios({
            url: imageLink.data.image.flash.jpg,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/jpg'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}.jpg`
            console.log(link)
            link.click()
          })

        axios({
            url: imageLink.data.image.flash.dng,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/DNG'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}.dng`
            link.click()
          })

        axios({
            url: imageLink.data.image.no_flash.txt,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'text/plain'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_no_flash.txt`
            link.click()
          })

        axios({
            url: imageLink.data.image.no_flash.jpg,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/jpg'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_no_flash.jpg`
            console.log(link)
            link.click()
          })

        axios({
            url: imageLink.data.image.no_flash.dng,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/DNG'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_no_flash.dng`
            link.click()
          })
      } catch (error) {
        this.$router.push({ name: 'login', query: { redirect: `/admin/files/${this.id}` } })
      }
    }
  },
}
</script>

<style lang="scss">
  .markup-tables {
    .table-wrapper {
      overflow: auto;
    }

    .va-table {
      width: 100%;
    }
  }

  .download-icon:hover {
    color: #154EC1;
  }
</style>
